<template>
  <div class="page-wrapper msg-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <pending-items-internal :title="false" />
      <div class="page-title">
        <h1>Group Registrations</h1>
      </div>
      <pending-search @onSearch="searchData" @onClear="clearData" />
      <div class="table-card">
        <div
          class="d-flex justify-content-between align-items-center top-pagination"
        >
          <page-result :rows.sync="rows" />
          <pagination :rows.sync="rows" :filters="filters" />
        </div>
        <simple-table :columns="columns">
          <template v-slot:tableBody>
            <pending-alt-table
              :rows.sync="rows"
              type="group"
              @onSuccess="getAll(filters)"
            />
          </template>
        </simple-table>
        <div
          class="d-flex justify-content-between align-items-center"
        >
          <page-result :rows.sync="rows" />
          <pagination :rows.sync="rows" :filters="filters" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import PendingSearch from "./partials/PendingSearch.vue";
import SimpleTable from "@/components/ui/SimpleTable.vue";
import PendingAltTable from "./partials/PendingAltTable.vue";
import PendingItemsInternal from "../Dashboard/partials/PendingItemsInternal.vue";
export default {
  name: "PendingGroupRegistrationList",
  components: { SimpleTable, PendingSearch, PendingItemsInternal, PendingAltTable },
  data: () => ({
    rows: [],
    columns: [
      { label: "Date" },
      { label: "Agent Name" },
      { label: "Name" },
      { label: "Website" },
      { label: "Progress Completed" },
      { label: "Action" },
    ],
    filters: { search: "",page_size:30 },
    breadcrumbItems: [{ title: "Group Registration List", active: true }],
  }),
  methods: {
    clearData() {
      this.filters.search = "";
      this.getAll(this.filters);
    },
    searchData(query) {
      this.filters.search = query;
      this.getAll(this.filters);
    },
    getAll(filters = null) {
      let url;
      let loader = this.$loading.show();
      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = `v2/get-pending-group-registration?${filterUrl}`;
      }
      Api.get(url)
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {
          this.rows = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.getAll(this.filters);
  },
};
</script>
<style src="@/assets/css/pending-details.css"></style>
